import "./style.css";
import dogovor from "./dogovor.svg";
import sale from "./bag.svg";
import help from "./ask.svg";
import contacts from "./cotacts.svg";
import profile from "./ava.png";
import { routes } from "../routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../Context";
import { useContext } from "react";
import loops from "./loop.svg";
import noticeA from "./notice.svg";
import notice from "./notN.svg";
export default function Header() {
  const [context, setContext] = useContext(Context);
  const location = useLocation();
  const Navigate = useNavigate();
  return (
    <div className="header">
      <p className="navigation">
        Админ панель /{" "}
        <span>
          {location.pathname == routes.tovar && "Товары"}
          {location.pathname == routes.main && "Главная"}
          {location.pathname == routes.orders && "Заказы"}
          {location.pathname == routes.themes && "Темы"}
          {location.pathname == routes.profile && "Настройки профиля"}
          {location.pathname.includes("orderDetails") && "Детали заказа"}
        </span>
      </p>
      {/* <Link to={routes.dogovor} target="_blank">
        <div className="dogovor header_button">
          <img src={dogovor} alt="" />
          <p>Ваш договор</p>
        </div>
      </Link> */}

      <div
        className="header_button sale"
        onClick={() => window.open("https://t.me/m/ITonqk95NDY6", "_blank")}
      >
        <p>Пополнить баланс</p>
        <img src={sale} alt="" />
      </div>

      {/*  <div className="searchM">
        <input type="text" placeholder="Поиск товара..." />
        <img src={loops} alt="" />
      </div> */}
      {/*   <img src={notice} alt="" /> */}
      {/*  <div className="profileSt">
        <div className="profileName">
          <p>Сосенко Михаил</p>
          <span>Руководитель</span>
        </div>
        <div
          alt=""
          className="profile"
          onClick={() => {
            Navigate(routes.login);
            localStorage.clear();
            window.location.reload();
          }}
        />
      </div> */}
    </div>
  );
}
