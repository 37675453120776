import "../admin.css";

import { Context } from "../../Context";
import { useContext, useEffect, useState } from "react";
import "./style.css";
import { Zoom } from "react-reveal";
import { Flip } from "react-reveal";
import Grafic from "../../Components/Chars";
import arrUp from "./img/up.svg";
import arrDown from "./img/down.svg";
import card from "./img/card.svg";
import CustomGr from "./CustomGr";
import { formatDate, getOrders } from "../../Api";
import Radial from "../../GraphicsComp/Radial";
import upgr from "./img/upgr.svg";
import { useNavigate } from "react-router-dom";
const data1 = [
  {
    name: "Page A",
    uv: 0,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 1200,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 1700,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const data2 = [
  {
    name: "Page A",
    uv: 0,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 1000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 1700,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 1780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 2490,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2290,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 1890,
    pv: 4300,
    amt: 2100,
  },
];
const data3 = [
  {
    name: "Page A",
    uv: 0,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 1670,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 1700,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 2490,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 1290,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 1890,
    pv: 4300,
    amt: 2100,
  },
];
export default function AdminMainPage() {
  const [context, setContext] = useContext(Context);
  const [sub, setSub] = useState();
  const [orders, setOrders] = useState();
  const Navigate = useNavigate();
  const [summMagazine, setSummMagazine] = useState(0);
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = dd + "." + mm + "." + yyyy;

  function getSubs() {
    let form = new FormData();
    form.append("ID", localStorage.id);
    fetch("https://comeback-team.ru/count.php", {
      method: "post",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSub(data);
      });
  }
  useEffect(() => {
    getSubs();
    getOrders().then((data) => {
      setOrders(data);
    });
  }, []);

  useEffect(() => {
    if (orders && orders.length > 0) {
      let sum = 0;
      for (let i = 0; i < orders.length; i++) {
        console.log(orders[i].StatusComp);
        if (orders[i].StatusComp == "Получен") {
          sum += Number(orders[i].summ);
        }
      }
      setSummMagazine(sum);
    }
  }, [orders]);
  return (
    <div className="container">
      <div className="financeCont">
        <div className="financeLeft">
          <h2>Показатели магазина</h2>
          <div className="activeFinance">
            <p>₽ {summMagazine}</p>
            <span>Доступных средств</span>
          </div>
        </div>
      </div>
      <div className="osnP">
        <div className="osnPElem">
          <div className="osnCont">
            <p>Посетителей на сайте</p>
            <div className="osnPrice">
              112.386{" "}
              <span>
                <img src={arrUp} alt="" /> + 32%
              </span>
            </div>
            <article>
              Данные <br /> за прошедшую неделю
            </article>
          </div>
          <CustomGr
            dataArr={data1}
            color={"#FF7A00"}
            fillColor={"#fbc18b"}
            sizeX={150}
          ></CustomGr>
        </div>
        <div className="osnPElem">
          <div className="osnCont">
            <p>Количество заказов</p>
            <div className="osnPrice">
              648{" "}
              <span style={{ color: "#FF3E3E" }}>
                <img src={arrDown} alt="" /> - 18%
              </span>
            </div>
            <article>
              Данные <br /> за прошедшую неделю
            </article>
          </div>
          <CustomGr
            dataArr={data2}
            color={"#3E95FF"}
            fillColor={"#a7cefe"}
            sizeX={150}
          ></CustomGr>
        </div>
        <div className="osnPElem">
          <div className="osnCont">
            <p>Чистая прибыль, ₽</p>
            <div className="osnPrice">
              422 632.00
              <span>
                {" "}
                <img src={arrUp} alt="" /> + 12%
              </span>
            </div>
            <article>
              Данные <br /> за прошедшую неделю
            </article>
          </div>
          <CustomGr
            dataArr={data3}
            color={"#B367FF"}
            fillColor={"#d3acfa"}
            sizeX={120}
          ></CustomGr>
        </div>
        <div className="timespend">
          <p>28</p>
          <span>Дней оплачено</span>
        </div>
      </div>
      <div className="bottomInfo">
        <div className="grM">
          <h3>Аналитика продаж товаров</h3>
          <Grafic />
        </div>
        <div className="lastOrders">
          <h3> Последние заказы</h3>
          <ul>
            {orders &&
              orders.length > 0 &&
              orders.map((em, index) => {
                let date = JSON.parse(em.timeOrder);
                return (
                  <li
                    key={`order${index}`}
                    onClick={() => Navigate("/orderDetails?key=" + em.ID)}
                  >
                    <img src={card} alt="" />
                    <div>
                      <p>
                        Заказ №<b>{em.ID}</b>
                      </p>
                      от {formatDate(date)} <br />
                      {em.StatusComp}
                    </div>
                    <span>+ {em.summ} ₽</span>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="Personal">
          <h3>Статистика персонала</h3>
          <Radial></Radial>
        </div>
      </div>
    </div>
  );
}
